.product-container {
  background-color: #e9e4e4dc;
}
.project-title {
  letter-spacing: 0.8px;
  color: rgb(0, 0, 0);
  font-family: "Taviraj", serif;
  font-weight: 900;
}
.projectDescription p{
  color: #433f3f;
  font-family: "Roboto", Sans-serif !important;
  font-weight: 400;


}

.print-img-container img {
  width: 200px;
}
.comming-soon-text {
  padding-bottom: 50px;
}

.comingSoon-img,
.onGoing-img {
  width: 300px;
  height: 250px;
}
.onGoing-img {
}
.ResponsiveMasonry {
  margin-left: 1.8%;

  width: 82% !important;
}
.my-masonry-grid{
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
  column-width: 20%;
}

@media (max-width: 768px) {
  .ResponsiveMasonry {
    margin-left: 24%;
  }
}
@media (max-width: 450px) {
  .ResponsiveMasonry {
    margin-left: 15%;
  }
}
/* @media (max-width: 1600px){
  .ResponsiveMasonry{
      margin-left:  -200px ;

  }
  


} */

/* .my-masonry-grid_column {
  padding-left: 30px; 
  background-clip: padding-box;
}

/* Style your items 
.my-masonry-grid_column > div change div to reference your elements you put in <Masonry> 
  background: grey;
  margin-bottom: 30px;
} 

*/

