@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

#home{
 height: 100vh;

    background-image: url("../../resource/img/background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.home-heading{

    white-space: nowrap;
  
    letter-spacing: 0.7px;
   color: white;
   font-family: 'Taviraj', serif;
}
.home-title{

    color: white;
   
}

/* Drop-Down menu responsive */
@media (max-width: 768px){
  
    .cHgOIG{
      margin-left: 1rem !important;
    }
    


}